.click-to-top {
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 52px;
  height: 52px;
  border-radius: 32px;
  right: 60px;
  bottom: 46px;
  background: white;
  cursor: pointer;
  opacity: 0;
  transform: translateY(10px);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
  font-size: 24px;
  user-select: none;
  color: #24678d;

  @include media-query(1024px) {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    right: 35px;
    font-size: 20px;
  }

  @include media-query($on-palm) {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    right: 20px;
    font-size: 16px;
  }
}

.click-to-top:hover {
  color: white;
  background: #a08484;
  transform: translate3d(0, -2px, 0);
  -webkit-transform: translate3d(0, -2px, 0);
}

.click-to-top.show {
  opacity: 1;
  /* transform: translateY(0); */
}

html[data-theme="dark"] {
  .click-to-top {
    color: #24678d;
    background: #34323D;
  }
  .click-to-top:hover {
    color: white;
    background: #a08484;
  }
}
